import React, {useState} from "react";
import {PaymentElement, useElements, useStripe} from "@stripe/react-stripe-js";

export default function PaymentForm(props) {
    const stripe = useStripe();
    const elements = useElements();
    const {checkoutData, setMessage} = props;

    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        setMessage("");
        setIsLoading(true);

        const {error} = await stripe.confirmPayment({
            elements,
            confirmParams: {
                return_url: window.location.href + `&payment_intent_id=${checkoutData.payment.payment_intent_id}&stage=complete`
            },
        });

        // This point will only be reached if there is an immediate error when
        // confirming the payment. Otherwise, your customer will be redirected to
        // your `return_url`. For some payment methods like iDEAL, your customer will
        // be redirected to an intermediate site first to authorize the payment, then
        // redirected to the `return_url`.
        if (error.type === "card_error" || error.type === "validation_error") {
            setMessage(error.message);
        } else {
            setMessage("An unexpected error occurred.");
        }

        setIsLoading(false);
    };

    const PurchaseDetails = () => {
        const {quantity, description, price, tax, total} = checkoutData.purchase;
        const curr = new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'});

        return (
            <>
                <h3>Purchase Details</h3>
                <h4>{quantity} x {description}: {curr.format(price)}</h4>
                <h4>Sales Tax: {curr.format(tax)}</h4>
                <h4>Total: {curr.format(total)}</h4>
            </>
        )
    }

    const Terms = () => {
        return (
            <>
                <div id="terms">
                    <h3>Code of Conduct</h3>
                    <p>
                        By pursuing and achieving a LaunchDarkly certification, candidates are joining an
                        elite professional tier, which is recognized by LaunchDarkly. By following the
                        Candidate Code of Conduct, candidates help ensure the integrity of certifications.
                    </p>
                    <h4>By signing up to take a LaunchDarkly certification, candidates agree to:</h4>
                    <ul>
                        <li>Comply with LaunchDarkly’s Candidate Code of Conduct and Program Policies.</li>
                        <li>Provide accurate and complete information when registering to take their certification.</li>
                    </ul>
                    <h4>Candidates agree they will not:</h4>
                    <ul>
                        <li>Falsify their identity or impersonate another individual.</li>
                        <li>Engage in cheating before, during, or after an exam. This includes:</li>
                        <ul>
                            <li>Obtaining actual exam content by any means including web postings, discussion forums,
                                chat rooms, etc.
                            </li>
                            <li>Submitting any answers or work that are not completely their own.</li>
                            <li>Talking or communicating with other exam-takers while testing is in progress.</li>
                        </ul>
                        <li>Make public the questions, answers, or any of the content of an exam.</li>
                        <p>
                            LaunchDarkly may prohibit candidates from taking any exam, and exam results or
                            credentials may be invalidated if we believe candidates have violated this
                            agreement or engaged in any misconduct. No exam refund will be provided.
                        </p>
                    </ul>
                </div>
            </>
        )
    }

    return (
        <form id="payment-form" onSubmit={handleSubmit}>
            <PurchaseDetails/>
            <PaymentElement id="payment-element"/>
            <Terms/>
            <button disabled={isLoading || !stripe || !elements} id="submit">
                <span id="button-text">
                  {isLoading ? <div className="spinner" id="spinner"></div> : "Agree & Pay"}
                </span>
            </button>
        </form>
    );
}
