import React from 'react';
import styles from './Header.module.css';

export default function Header(props) {
    const {checkoutData} = props;

    let returnUrl = `https://${process.env.REACT_APP_SKILLJAR_DOMAIN}`;
    if (checkoutData) {
        returnUrl += checkoutData.purchase.return_url;
    }

    return (
        <header className={styles.headerBg}>
            <div className={styles.headerWrapper}>
                <div>
                    <img
                        className={styles.logo}
                        src={process.env.PUBLIC_URL + '/header-logo.png'}
                        alt="LaunchDarkly Academy"
                    />
                </div>
                <div>
                    <a className={styles.headerLink} href={returnUrl}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className={styles.linkIcon}
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M15.75 19.5L8.25 12l7.5-7.5"
                            />
                        </svg>
                        <span>Go Back</span>
                    </a>
                </div>
            </div>
        </header>
    );
}
