import React, {useEffect, useState} from "react";
import {loadStripe} from "@stripe/stripe-js";
import {Elements} from "@stripe/react-stripe-js";

import "./App.css";
import AddressForm from "./AddressForm";
import PaymentForm from "./PaymentForm";
import Header from "./Header";
import Footer from "./Footer";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY, {
    betas: ['address_element_beta_1'],
});

export let apiBaseUrl = "";
if (process.env.REACT_APP_API_DOMAIN) {
    apiBaseUrl = `https://${process.env.REACT_APP_API_DOMAIN}`;
}

export default function App() {
    const [checkoutData, setCheckoutData] = useState(null);
    const [message, setMessage] = useState("");
    const [status, setStatus] = useState("Loading Order");

    const params = new URLSearchParams(window.location.search);
    const purchaseId = params.get('purchase_id');
    const paymentIntentId = params.get('payment_intent_id');
    const stage = params.get('stage');

    useEffect(() => {
        if (stage === 'complete') {

            setStatus("Order Complete");
            fetch(`${apiBaseUrl}/payment`, {
                method: "POST",
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify({
                    purchase_id: purchaseId,
                    payment_intent_id: paymentIntentId
                })
            })
                .then((res) => {
                    if (res.ok) {
                        return res.json();
                    }
                    return Promise.reject(res);
                })
                .then((data) => window.location.href = data.redirect_url)
                .catch((res) => {
                    res.json()
                        .then((err) => setMessage(err.error))
                        .catch(() => setMessage("An unexpected error occurred. If you continue to encounter this error, please contact support@launchdarkly.com."));
                });

        } else {
            setStatus("Loading Order");
            fetch(`${apiBaseUrl}/checkout`, {
                method: "POST",
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify({purchase: {id: purchaseId}})
            })
                .then((res) => {
                    if (res.ok) {
                        return res.json();
                    }
                    return Promise.reject(res);
                })
                .then((data) => {
                    if (data.purchase.state === "SUCCESS") {
                        setStatus("Order Complete");
                        window.location.href = `https://${process.env.REACT_APP_SKILLJAR_DOMAIN}/accounts/profile`;
                    } else {
                        setCheckoutData(data);
                    }
                })
                .catch((res) => {
                    res.json()
                        .then((err) => setMessage(err.error))
                        .catch(() => setMessage("An unexpected error occurred. If you continue to encounter this error, please contact support@launchdarkly.com."));
                });
        }
    }, [purchaseId, paymentIntentId, stage]);

    const options = {
        appearance: {
            theme: 'stripe'
        }
    };

    if (checkoutData) {
        options.clientSecret = checkoutData.payment.client_secret;
    }

    return (
        <>
            <Header checkoutData={checkoutData}/>
            <div className="App">
                {!checkoutData &&
                    <>
                        <h3>{status}...</h3>
                        <div className="spinner loading"></div>
                    </>
                }
                {checkoutData &&
                    <Elements options={options} stripe={stripePromise}>
                        {checkoutData.stage === 'address' &&
                            <AddressForm checkoutData={checkoutData} setCheckoutData={setCheckoutData}
                                         setMessage={setMessage}/>
                        }
                        {checkoutData.stage === 'payment' &&
                            <PaymentForm checkoutData={checkoutData} setCheckoutData={setCheckoutData}
                                         setMessage={setMessage}/>
                        }
                    </Elements>
                }
                {message &&
                    <div id="message">{message}</div>
                }
            </div>
            <Footer />
        </>
    );
}
