import React from 'react';
import styles from './Footer.module.css';

export default function Footer() {
  return (
    <footer className={styles.footerContainer}>
      <div className={styles.footerLeft}>
        <span>
          <svg
            width="40"
            height="40"
            viewBox="0 0 57 56"
            fill="#939598"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M28.6015 55.7159C28.1691 55.7159 27.7367 55.4696 27.4896 55.0387C27.3042 54.6077 27.3042 54.1768 27.6131 53.8074L40.771 35.769L17.7292 45.1268C17.5439 45.1883 17.4204 45.2499 17.235 45.2499C16.6791 45.2499 16.1849 44.8805 16.0613 44.388C15.876 43.8955 16.1231 43.3414 16.5555 43.0336L36.8175 31.1516L1.17373 29.12C0.432435 29.0584 0 28.5044 0 27.8887C0 27.3346 0.370661 26.719 1.17373 26.6574L36.8175 24.6258L16.5555 12.7438C16.1231 12.436 15.876 11.8819 16.0613 11.3894C16.2466 10.8969 16.6791 10.5275 17.235 10.5275C17.4204 10.5275 17.5439 10.5891 17.7292 10.6507L40.771 20.0085L27.6131 1.9085C27.366 1.53911 27.3042 1.0466 27.4896 0.677209C27.6749 0.246258 28.1073 0 28.6015 0C28.9104 0 29.2192 0.123129 29.4663 0.369387L55.6587 26.4112C56.0293 26.7806 56.2146 27.3346 56.2146 27.8271C56.2146 28.3197 56.0293 28.8122 55.6587 29.2431L29.4663 55.3465C29.2192 55.5927 28.9104 55.7159 28.6015 55.7159Z"
              fill="#939598"
            ></path>
            <path
              d="M28.6015 55.7159C28.1691 55.7159 27.7367 55.4696 27.4896 55.0387C27.3042 54.6077 27.3042 54.1768 27.6131 53.8074L40.771 35.769L17.7292 45.1268C17.5439 45.1883 17.4204 45.2499 17.235 45.2499C16.6791 45.2499 16.1849 44.8805 16.0613 44.388C15.876 43.8955 16.1231 43.3414 16.5555 43.0336L36.8175 31.1516L1.17373 29.12C0.432435 29.0584 0 28.5044 0 27.8887C0 27.3346 0.370661 26.719 1.17373 26.6574L36.8175 24.6258L16.5555 12.7438C16.1231 12.436 15.876 11.8819 16.0613 11.3894C16.2466 10.8969 16.6791 10.5275 17.235 10.5275C17.4204 10.5275 17.5439 10.5891 17.7292 10.6507L40.771 20.0085L27.6131 1.9085C27.366 1.53911 27.3042 1.0466 27.4896 0.677209C27.6749 0.246258 28.1073 0 28.6015 0C28.9104 0 29.2192 0.123129 29.4663 0.369387L55.6587 26.4112C56.0293 26.7806 56.2146 27.3346 56.2146 27.8271C56.2146 28.3197 56.0293 28.8122 55.6587 29.2431L29.4663 55.3465C29.2192 55.5927 28.9104 55.7159 28.6015 55.7159Z"
              fill="#939598"
            ></path>
            <defs>
              <linearGradient
                id="paint0_linear"
                x1="28.1073"
                y1="0"
                x2="28.1073"
                y2="55.7159"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="white"></stop>
                <stop offset="1" stop-color="white" stop-opacity="0"></stop>
              </linearGradient>
            </defs>
          </svg>
        </span>
        <span className={styles.copyright}>©2022 Catamorphic Co.</span>
      </div>
      {/* <div className={styles.footerRight}> */}
      <div className={styles.footerMenuContainer}>
        <a
          className={styles.twitter}
          href="https://twitter.com/launchdarkly"
          target="_blank"
          rel="noreferrer"
        >
          Twitter
        </a>
        <a
          className={styles.linkedin}
          href="https://www.linkedin.com/company/launchdarkly/mycompany/"
          target="_blank"
          rel="noreferrer"
        >
          LinkedIn
        </a>
        <a
          className={styles.meetup}
          href="https://www.meetup.com/Test-in-Production/"
          target="_blank"
          rel="noreferrer"
        >
          Meetup
        </a>
        <a
          className={styles.youtube}
          href="https://www.youtube.com/channel/UCyVjMONrMfeY9tsWZtUFtFw/videos"
          target="_blank"
          rel="noreferrer"
        >
          YouTube
        </a>
      </div>
    </footer>
  );
}
