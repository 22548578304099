import React, {useState} from 'react';
import {AddressElement, useElements, useStripe} from '@stripe/react-stripe-js';
import {apiBaseUrl} from "./App";

const AddressForm = (props) => {
    const stripe = useStripe();
    const elements = useElements();
    const {checkoutData, setCheckoutData, setMessage} = props;

    const [isLoading, setIsLoading] = useState(false);

    const options = {
        mode: 'shipping', fields: {
            phone: 'always',
        }, validation: {
            phone: {
                required: 'never',
            },
        }, defaultValues: {
            name: checkoutData.customer.name,
        }
    }

    if (checkoutData.customer.address) {
        options.defaultValues.address = checkoutData.customer.address
    }

    const handleChange = (e) => {
        setCheckoutData({
            ...checkoutData, customer: {
                ...checkoutData.customer, address: e.value.address, phone: e.value.phone
            }
        });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!elements) {
            return;
        }

        setMessage("");
        setIsLoading(true);

        await fetch(`${apiBaseUrl}/address-tax`, {
            method: "POST", headers: {"Content-Type": "application/json"}, body: JSON.stringify(checkoutData)
        })
            .then((res) => {
                if (res.ok) {return res.json();}
                return Promise.reject(res);
            })
            .then((data) => setCheckoutData({
                ...checkoutData,
                stage: data.stage,
                purchase: data.purchase,
            }))
            .catch((res) => {
                res.json()
                    .then((err) => setMessage(err.error))
                    .catch(() => setMessage("An unexpected error occurred. If you continue to encounter this error, please contact support@launchdarkly.com."));
            });

        setIsLoading(false);
    }

    return (
        <form onSubmit={handleSubmit}>
            <h3>Shipping Address</h3>
            <AddressElement id="address-element" options={options} onChange={handleChange}/>
            <button disabled={isLoading || !stripe || !elements} id="submit">
                <span id="button-text">
                  {isLoading ? <div className="spinner" id="spinner"></div> : "Proceed to Checkout"}
                </span>
            </button>
        </form>
    );
};

export default AddressForm;
